import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1080.000000 1080.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)">
  
<path d="M5048 9299 c-129 -10 -373 -53 -523 -90 -324 -81 -617 -201 -905
-372 -47 -28 -272 -178 -335 -224 -118 -85 -304 -258 -291 -270 6 -7 120 72
159 110 49 49 126 110 187 149 30 20 70 51 89 71 19 20 40 33 47 30 8 -3 14
-1 14 3 0 5 14 15 30 23 17 7 43 23 58 36 15 12 54 37 87 55 33 18 67 38 75
45 15 12 124 65 134 65 3 0 15 9 26 20 12 12 24 18 29 16 9 -6 181 68 190 81
3 5 35 16 71 25 36 9 91 28 122 42 32 15 79 29 105 32 26 4 82 15 123 27 41
11 95 24 120 28 128 25 160 31 220 39 36 4 79 14 95 20 44 17 775 15 786 -2 4
-7 38 -15 76 -19 87 -9 149 -22 243 -49 41 -12 120 -30 175 -41 55 -11 114
-26 130 -34 40 -18 123 -45 160 -52 17 -3 37 -11 45 -19 23 -20 168 -84 191
-84 10 0 19 -4 19 -9 0 -10 125 -71 145 -71 7 0 15 -4 17 -9 3 -9 70 -53 208
-136 89 -53 105 -63 139 -91 14 -12 51 -37 81 -57 30 -19 71 -51 89 -71 19
-20 39 -36 43 -36 4 0 18 -9 31 -21 12 -11 36 -33 52 -48 17 -15 47 -40 67
-55 42 -31 54 -57 39 -83 -5 -10 -37 -48 -71 -83 -35 -36 -74 -81 -89 -100
-14 -19 -37 -46 -51 -60 -14 -14 -46 -49 -72 -78 -27 -31 -54 -52 -67 -52 -12
0 -50 28 -90 68 -107 104 -136 129 -170 146 -17 9 -31 21 -31 25 0 5 -21 23
-47 39 -27 17 -80 52 -118 79 -132 93 -312 192 -380 209 -16 4 -44 17 -62 30
-19 13 -60 30 -91 39 -31 9 -73 22 -92 30 -59 23 -192 62 -255 75 -33 6 -81
18 -107 25 -26 7 -84 16 -130 20 -46 4 -118 13 -162 21 -78 14 -379 20 -410 8
-27 -10 -19 -43 12 -49 15 -3 43 -10 62 -16 19 -5 67 -16 105 -25 39 -8 97
-25 130 -38 33 -13 105 -38 160 -56 55 -19 114 -42 130 -52 17 -10 35 -18 42
-18 25 0 327 -154 392 -199 14 -10 46 -27 71 -38 25 -11 54 -29 65 -40 11 -11
46 -34 78 -51 32 -18 64 -38 71 -45 7 -6 34 -25 59 -41 26 -17 47 -34 47 -38
0 -4 5 -8 10 -8 6 0 21 -9 33 -21 12 -11 47 -36 77 -56 30 -20 69 -49 85 -65
36 -35 143 -123 196 -161 59 -42 70 -27 14 19 -27 21 -69 58 -94 80 -115 104
-624 464 -656 464 -3 0 -38 20 -78 43 -39 24 -110 63 -157 87 -47 23 -89 46
-95 50 -5 4 -46 22 -90 40 -95 38 -124 50 -190 80 -27 13 -69 31 -93 41 -74
32 -50 44 84 42 182 -2 519 -80 699 -162 22 -10 51 -22 65 -26 62 -19 254
-119 344 -179 55 -36 101 -66 103 -66 1 0 50 -34 107 -75 92 -67 154 -117 252
-205 108 -96 141 -81 355 163 137 157 145 177 108 253 -24 51 -105 124 -285
260 -239 180 -506 335 -764 443 -69 29 -134 57 -145 61 -84 38 -414 122 -610
156 -230 41 -604 55 -867 33z"/>
<path d="M10377 9064 c-4 -4 -7 -20 -7 -36 0 -20 7 -32 24 -40 39 -17 58 -4
54 39 -3 34 -6 38 -33 41 -17 2 -34 0 -38 -4z"/>
<path d="M2990 8241 c0 -10 71 -100 155 -196 78 -88 112 -123 144 -143 l26
-17 -20 32 c-12 18 -43 56 -71 85 -27 29 -67 75 -89 103 -43 53 -131 145 -140
145 -3 0 -5 -4 -5 -9z"/>
<path d="M4190 8124 c-47 -8 -123 -21 -170 -29 -119 -20 -252 -58 -410 -117
-162 -60 -230 -90 -230 -101 0 -13 57 2 145 38 197 80 389 133 506 142 122 9
117 -8 -31 -109 -222 -153 -370 -273 -370 -301 0 -18 47 7 85 45 22 22 58 52
80 66 22 13 68 47 102 73 35 27 68 49 76 49 7 0 29 15 49 33 56 49 60 52 108
66 25 7 90 35 145 62 55 27 106 49 113 49 8 0 12 9 10 23 -3 20 -9 22 -63 23
-33 1 -98 -5 -145 -12z"/>
<path d="M4457 8114 c-10 -11 2 -24 17 -18 27 10 193 -15 266 -40 40 -14 85
-26 99 -26 32 0 262 -79 276 -94 12 -13 79 -42 117 -50 15 -3 33 -13 42 -21 8
-8 19 -15 24 -15 6 0 12 -3 14 -7 2 -5 24 -18 49 -31 26 -12 52 -29 59 -37 17
-20 61 -20 54 0 -5 11 1 15 20 15 32 0 35 -25 4 -34 -13 -4 -81 -11 -153 -16
-289 -21 -370 -34 -370 -61 0 -18 62 -49 100 -49 17 0 50 -6 75 -14 25 -8 72
-22 105 -31 90 -25 161 -49 255 -89 46 -20 88 -36 93 -36 13 0 152 -68 178
-88 13 -10 50 -31 82 -47 31 -16 57 -32 57 -36 0 -4 12 -9 28 -13 15 -3 49
-23 76 -43 27 -21 59 -45 72 -53 55 -37 153 -110 227 -172 45 -38 85 -68 89
-68 19 0 4 19 -44 58 -29 23 -60 49 -69 57 -16 15 -94 74 -139 105 -12 8 -48
34 -81 58 -32 23 -63 42 -69 42 -5 0 -10 4 -10 8 0 4 -24 20 -52 35 -29 15
-71 38 -93 52 -22 13 -61 34 -87 47 -27 13 -48 26 -48 30 0 5 -5 8 -11 8 -6 0
-25 7 -42 14 -103 47 -229 100 -277 116 -72 23 -166 65 -173 77 -24 39 112 59
304 44 127 -10 342 -46 429 -72 94 -28 240 -93 320 -143 57 -36 111 -74 353
-248 29 -21 61 -38 72 -38 23 0 31 -7 -101 97 -197 155 -235 183 -300 224 -38
23 -78 50 -89 59 -11 9 -47 28 -80 41 -33 12 -67 29 -76 36 -8 7 -22 13 -29
13 -31 0 -79 25 -91 47 -7 13 -19 23 -27 23 -12 0 -12 -3 -2 -15 11 -13 8 -15
-20 -15 -25 0 -30 3 -22 13 8 9 0 17 -34 31 -47 19 -54 30 -26 41 15 6 14 8
-8 17 -14 5 -27 9 -30 9 -3 0 -12 6 -20 14 -8 7 -51 28 -95 45 -44 17 -84 36
-90 41 -5 5 -16 9 -25 9 -9 0 -40 11 -68 25 -29 14 -63 25 -77 25 -14 0 -47
11 -74 24 -45 23 -64 28 -171 42 -25 3 -59 12 -77 20 -17 8 -44 14 -60 14 -15
0 -44 5 -63 11 -60 18 -469 46 -483 33z m439 -41 c-7 -7 -77 -5 -85 3 -2 2 17
4 44 4 27 0 45 -3 41 -7z m249 -73 c-3 -5 -16 -10 -28 -9 -20 1 -20 2 3 9 14
4 26 8 28 9 2 1 0 -3 -3 -9z m78 3 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0
19 -2 13 -5z m161 -49 c18 -7 12 -24 -10 -24 -8 0 -14 7 -14 15 0 17 2 18 24
9z m56 -50 c0 -8 -4 -12 -10 -9 -5 3 -10 10 -10 16 0 5 5 9 10 9 6 0 10 -7 10
-16z m115 -24 c3 -5 -3 -10 -14 -10 -12 0 -21 5 -21 10 0 6 6 10 14 10 8 0 18
-4 21 -10z m80 -19 c3 -5 1 -12 -5 -16 -5 -3 -10 1 -10 9 0 18 6 21 15 7z
m-35 -31 c0 -13 -5 -17 -14 -14 -24 9 -28 34 -6 34 13 0 20 -7 20 -20z m-133
4 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m268 -64 c0 -8 11
-17 24 -20 13 -3 26 -11 29 -19 4 -10 -4 -12 -34 -7 -22 3 -61 8 -88 11 -53 5
-74 15 -35 16 22 0 23 1 4 9 -18 8 -18 9 5 9 14 1 32 6 40 11 21 14 55 8 55
-10z"/>
<path d="M3345 7880 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M3620 7534 c0 -11 33 -58 67 -95 27 -30 112 -128 133 -153 31 -38
103 -106 111 -106 18 0 22 -6 -120 159 -25 30 -57 66 -70 80 -13 14 -33 37
-45 51 -36 41 -76 74 -76 64z"/>
<path d="M10441 7516 c-27 -28 -27 -52 -1 -66 27 -15 56 -4 63 21 7 27 1 42
-22 56 -15 10 -22 8 -40 -11z"/>
<path d="M7048 7483 c-24 -26 -75 -80 -115 -121 -80 -83 -130 -149 -119 -160
4 -4 24 11 44 34 20 22 80 86 132 143 52 57 99 111 104 120 5 9 14 19 20 23 7
5 4 8 -6 8 -10 0 -37 -21 -60 -47z"/>
<path d="M8791 7426 c-24 -44 32 -89 67 -54 17 17 15 42 -6 61 -26 23 -46 21
-61 -7z"/>
<path d="M4130 7189 c-76 -8 -137 -25 -127 -35 3 -3 62 0 131 6 151 15 331 10
364 -11 12 -8 21 -21 20 -29 -2 -8 -41 -41 -88 -73 -96 -65 -170 -133 -170
-156 0 -10 12 -3 38 20 57 54 111 88 272 169 88 44 99 48 149 43 39 -4 52 -3
47 6 -4 6 -22 11 -40 11 -22 0 -38 7 -47 20 -12 17 -32 21 -129 30 -131 11
-310 11 -420 -1z"/>
<path d="M4797 7103 c3 -7 16 -13 30 -13 30 0 22 16 -11 22 -17 4 -23 1 -19
-9z"/>
<path d="M4943 7072 c-32 -5 -29 -22 5 -23 16 -1 20 -3 10 -6 -10 -2 -18 -8
-18 -11 1 -9 111 -63 128 -62 7 0 11 -4 8 -9 -6 -8 90 -61 112 -61 6 0 12 -7
12 -15 0 -9 10 -19 23 -24 12 -5 32 -19 44 -30 12 -12 28 -21 35 -21 18 1 49
26 41 33 -3 4 -3 7 1 7 4 0 14 -7 23 -15 21 -22 26 -13 12 19 -7 14 -19 26
-27 26 -9 0 -12 3 -9 7 12 11 -14 33 -39 33 -13 0 -24 4 -24 9 0 5 -10 12 -22
16 -15 4 -18 8 -10 12 25 10 -45 38 -87 34 -11 -1 -11 -4 0 -17 7 -8 20 -13
30 -11 12 3 16 0 12 -9 -7 -18 -57 -18 -72 0 -10 11 -10 20 -1 36 12 23 4 43
-9 22 -6 -9 -15 -7 -36 10 -36 28 -112 55 -142 50z m97 -42 c0 -5 -4 -10 -9
-10 -6 0 -13 5 -16 10 -3 6 1 10 9 10 9 0 16 -4 16 -10z m190 -110 c0 -5 -7
-10 -15 -10 -8 0 -15 5 -15 10 0 6 7 10 15 10 8 0 15 -4 15 -10z m95 -40 c7
-12 -34 -30 -52 -23 -6 3 -14 11 -17 19 -4 10 3 14 29 14 18 0 37 -5 40 -10z"/>
<path d="M5360 6904 c0 -8 5 -12 10 -9 6 4 8 11 5 16 -9 14 -15 11 -15 -7z"/>
<path d="M5420 6850 c-9 -16 -8 -20 5 -20 8 0 15 -9 15 -19 0 -27 85 -35 87
-9 1 12 -9 19 -33 24 -23 4 -33 11 -30 19 3 8 -2 16 -10 19 -22 8 -22 8 -34
-14z m70 -46 c0 -2 -9 -4 -21 -4 -11 0 -18 4 -14 10 5 8 35 3 35 -6z"/>
<path d="M4250 6842 c0 -19 23 -61 59 -105 60 -77 232 -253 263 -272 l32 -19
-22 30 c-13 16 -30 40 -40 54 -9 13 -53 61 -97 106 -44 45 -96 101 -116 125
-21 24 -46 55 -58 69 -11 14 -20 19 -21 12z"/>
<path d="M6420 6777 c-19 -23 -59 -69 -90 -102 -144 -152 -172 -185 -167 -197
5 -14 58 37 182 176 69 78 122 153 113 161 -3 3 -20 -14 -38 -38z"/>
<path d="M5335 6793 c11 -3 23 -10 26 -15 3 -5 19 -6 35 -2 25 6 26 8 9 15
-11 4 -36 7 -55 7 -21 0 -27 -2 -15 -5z"/>
<path d="M10003 6792 c-36 -4 -51 -32 -38 -74 14 -42 52 -51 85 -18 41 41 11
98 -47 92z"/>
<path d="M5595 6770 c-3 -5 -16 -10 -28 -10 -15 0 -18 -3 -10 -11 12 -12 53 4
53 21 0 13 -7 13 -15 0z"/>
<path d="M5310 6735 c-30 -7 -75 -14 -100 -14 -65 -1 -165 -24 -241 -55 -36
-14 -70 -26 -75 -26 -5 0 -25 -13 -44 -30 -19 -16 -43 -29 -53 -30 -24 0 -157
-97 -157 -114 0 -10 50 13 133 62 208 121 314 152 544 159 165 5 267 -6 386
-42 45 -14 147 -65 176 -88 7 -6 21 -16 31 -22 9 -5 42 -27 71 -47 89 -62 108
-42 21 22 -26 19 -49 37 -52 40 -3 3 -34 27 -70 54 -80 60 -152 95 -207 103
-24 3 -43 9 -43 13 0 7 -61 14 -200 24 -36 2 -89 -1 -120 -9z"/>
<path d="M5288 6313 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M5388 6313 c12 -2 30 -2 40 0 9 3 -1 5 -23 4 -22 0 -30 -2 -17 -4z"/>
<path d="M5213 6299 c-18 -5 -33 -14 -33 -19 0 -12 14 -13 24 -2 4 4 22 12 39
19 38 14 16 15 -30 2z"/>
<path d="M5110 6233 c-90 -71 -150 -190 -150 -297 0 -102 48 -224 113 -289
147 -147 404 -153 550 -12 56 54 66 68 96 133 34 73 42 143 26 227 -15 80 -35
130 -43 106 -2 -7 1 -17 8 -21 9 -6 15 -43 18 -113 4 -88 1 -114 -17 -168 -25
-75 -97 -159 -176 -205 -48 -28 -56 -29 -170 -29 -117 0 -122 1 -189 35 -51
25 -80 48 -110 86 -78 100 -104 269 -59 384 22 55 116 169 141 170 6 0 12 7
12 15 0 19 4 20 -50 -22z"/>
<path d="M5560 6250 c0 -5 4 -10 8 -10 5 0 26 -16 46 -36 34 -33 58 -41 41
-15 -11 18 -95 72 -95 61z"/>
<path d="M7132 6038 c-7 -7 -12 -19 -12 -28 0 -41 61 -55 81 -18 10 18 8 24
-12 39 -28 22 -41 23 -57 7z"/>
<path d="M3568 5144 c-5 -4 -8 -106 -8 -226 l0 -218 65 0 c83 0 113 14 121 55
3 18 4 105 2 194 -4 192 -3 191 -105 198 -37 3 -71 2 -75 -3z m117 -219 l0
-160 -32 -3 -33 -3 0 166 0 166 33 -3 32 -3 0 -160z"/>
<path d="M3820 5132 l-25 -20 0 -204 0 -203 28 -3 27 -3 0 80 0 81 35 0 35 0
0 -80 0 -80 30 0 30 0 0 199 c0 233 -2 240 -86 248 -39 4 -54 1 -74 -15z m100
-127 l0 -85 -35 0 -35 0 0 78 c0 43 3 82 7 85 3 4 19 7 35 7 l28 0 0 -85z"/>
<path d="M4060 5138 c-22 -12 -26 -22 -30 -78 -9 -102 -2 -120 58 -154 l52
-30 0 -59 c0 -58 0 -58 -27 -55 -20 2 -29 9 -31 26 -2 16 -10 22 -27 22 -32 0
-36 -10 -24 -54 7 -25 18 -40 35 -46 39 -15 90 -12 113 8 20 15 22 25 19 107
l-3 89 -55 31 -55 31 0 54 c0 53 1 55 28 58 24 3 27 0 27 -27 0 -28 3 -31 30
-31 29 0 30 2 30 44 0 24 -6 48 -14 55 -21 18 -98 23 -126 9z"/>
<path d="M4292 5140 c-12 -5 -27 -21 -32 -35 -6 -15 -10 -112 -10 -216 l0
-189 30 0 30 0 0 80 0 80 35 0 35 0 0 -80 0 -80 30 0 30 0 0 199 c0 175 -2
201 -17 220 -21 24 -95 36 -131 21z m86 -137 l3 -83 -36 0 -35 0 0 78 c0 43 3
82 7 86 4 4 19 6 33 4 24 -3 25 -6 28 -85z"/>
<path d="M4595 5138 c-3 -7 -4 -108 -3 -223 l3 -210 28 -3 27 -3 0 79 0 79 47
6 c66 7 73 21 73 148 0 86 -3 109 -16 120 -24 20 -152 25 -159 7z m115 -133
l0 -85 -30 0 -30 0 0 85 0 85 30 0 30 0 0 -85z"/>
<path d="M4820 5147 c-3 -3 -4 -105 -2 -227 l3 -221 26 3 26 3 -2 88 c-2 86
-2 88 20 85 19 -2 24 -13 35 -68 23 -111 22 -110 49 -110 30 0 31 8 6 110 -17
72 -17 76 1 94 15 16 18 36 18 107 0 117 -12 132 -106 137 -38 2 -71 2 -74 -1z
m118 -134 c3 -71 2 -73 -24 -79 -35 -9 -44 7 -44 82 0 69 4 76 40 72 24 -3 25
-6 28 -75z"/>
<path d="M5050 4925 l0 -225 75 0 75 0 0 30 c0 29 -2 30 -45 30 l-45 0 0 70 0
70 40 0 c38 0 40 2 40 30 0 28 -2 30 -40 30 l-40 0 0 64 0 65 43 3 c37 3 42 6
42 28 0 24 -3 25 -72 28 l-73 3 0 -226z"/>
<path d="M5239 5146 c-2 -2 -3 -104 -1 -225 l3 -221 24 0 25 0 1 133 c1 112 3
125 11 87 26 -116 33 -135 55 -138 17 -2 24 3 27 20 2 13 11 55 19 93 l15 70
1 -133 1 -133 28 3 27 3 0 220 0 220 -31 3 c-35 3 -30 17 -70 -171 -9 -43 -18
-77 -19 -75 -2 2 -13 50 -25 108 -29 145 -27 140 -59 140 -15 0 -30 -2 -32 -4z"/>
<path d="M5530 4925 l0 -225 30 0 30 0 0 225 0 225 -30 0 -30 0 0 -225z"/>
<path d="M5644 5136 c-3 -7 -4 -99 -2 -204 3 -173 5 -192 23 -212 17 -19 28
-21 78 -18 91 7 90 4 91 240 l1 203 -28 0 -29 0 1 -180 c1 -99 -3 -186 -8
-193 -4 -8 -21 -12 -37 -10 l-29 3 -3 193 -2 192 -26 0 c-13 0 -27 -6 -30 -14z"/>
<path d="M5890 4925 l0 -226 27 3 27 3 -1 130 c-3 139 1 143 26 25 14 -64 19
-75 38 -78 25 -4 24 -4 47 113 l14 70 1 -132 1 -133 30 0 30 0 0 225 0 225
-33 0 c-37 0 -37 1 -68 -170 -6 -36 -15 -69 -19 -73 -4 -4 -18 47 -31 115
l-25 123 -32 3 -32 3 0 -226z"/>
<path d="M6290 4926 l0 -226 65 0 c35 0 76 5 90 12 l25 11 0 192 c0 230 1 227
-111 233 l-69 4 0 -226z m120 -1 l0 -165 -30 0 -30 0 0 165 0 165 30 0 30 0 0
-165z"/>
<path d="M6570 5143 c-49 -19 -50 -24 -50 -240 l0 -203 30 0 30 0 0 80 0 80
35 0 35 0 0 -81 0 -80 28 3 27 3 0 202 c0 175 -2 205 -16 219 -18 17 -93 28
-119 17z m75 -76 c4 -13 5 -50 3 -82 -3 -60 -3 -60 -35 -63 l-33 -3 0 85 0 86
30 0 c23 0 31 -5 35 -23z"/>
<path d="M6740 5120 c0 -25 4 -30 25 -30 l25 0 2 -192 3 -193 28 -3 27 -3 0
195 0 196 30 0 c27 0 30 3 30 30 l0 30 -85 0 -85 0 0 -30z"/>
<path d="M6985 5143 c-44 -12 -45 -16 -45 -235 l0 -209 28 3 c27 3 27 4 28 74
2 81 6 94 32 89 9 -2 20 -4 24 -4 4 -1 8 -36 10 -78 3 -77 3 -78 31 -81 l27
-3 0 203 c0 153 -3 209 -13 222 -14 19 -84 30 -122 19z m75 -139 l0 -85 -32 3
c-18 2 -32 6 -30 11 1 4 2 41 2 82 l0 75 30 0 30 0 0 -86z"/>
<path d="M112 5108 c-17 -17 -15 -65 4 -72 33 -13 55 -6 65 20 16 45 -36 85
-69 52z"/>
<path d="M7576 5038 c-10 -5 -29 -8 -44 -5 -32 6 -52 -1 -52 -19 0 -31 67 -44
115 -22 28 13 29 16 12 40 -10 12 -17 14 -31 6z"/>
<path d="M7645 4951 l-30 -16 28 -3 c15 -2 39 -2 54 0 l27 3 -25 16 c-23 15
-27 16 -54 0z"/>
<path d="M7406 4941 c-7 -11 33 -47 71 -63 18 -8 49 -12 69 -10 l36 4 -78 39
c-85 42 -90 44 -98 30z"/>
<path d="M4555 4920 c-4 -6 -3 -16 3 -22 6 -6 12 -6 17 2 4 6 3 16 -3 22 -6 6
-12 6 -17 -2z"/>
<path d="M2264 4906 c-12 -31 1 -51 32 -54 27 -3 33 1 38 24 5 18 3 30 -6 35
-21 14 -58 10 -64 -5z"/>
<path d="M7730 4910 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M7703 4861 c-49 -33 -47 -43 5 -29 46 13 62 25 55 44 -7 18 -10 17
-60 -15z"/>
<path d="M7336 4861 c-17 -28 89 -90 167 -97 l52 -5 -61 29 c-34 17 -79 41
-100 56 -42 28 -50 31 -58 17z"/>
<path d="M7707 4738 c-81 -40 -187 -48 -279 -20 -38 11 -66 16 -63 12 3 -5 20
-12 38 -15 17 -4 35 -13 39 -19 5 -7 45 -11 110 -11 86 0 111 4 155 23 28 13
54 31 58 39 3 9 4 17 3 17 -2 1 -29 -11 -61 -26z"/>
<path d="M9612 4558 c-6 -6 -12 -27 -12 -44 0 -47 35 -57 67 -19 22 24 23 29
10 49 -17 25 -47 32 -65 14z"/>
<path d="M7410 4397 c-17 -9 -26 -22 -28 -43 -4 -41 18 -58 63 -50 34 7 36 9
33 43 -4 47 -32 68 -68 50z"/>
<path d="M3497 3813 c-4 -3 -7 -19 -7 -35 0 -24 4 -28 29 -28 33 0 46 22 31
51 -10 19 -40 26 -53 12z"/>
<path d="M10718 3635 c-8 -18 -9 -35 -2 -53 8 -22 16 -28 47 -30 l37 -3 0 55
0 56 -35 0 c-28 0 -38 -5 -47 -25z"/>
<path d="M2647 3612 c-37 -40 -8 -94 40 -76 43 15 51 39 23 69 -28 30 -41 31
-63 7z"/>
<path d="M5752 3488 c4 -39 31 -50 47 -19 15 28 3 51 -26 51 -21 0 -24 -4 -21
-32z"/>
<path d="M6995 3226 c-27 -19 -13 -48 22 -44 31 3 44 35 21 49 -18 12 -21 11
-43 -5z"/>
<path d="M4404 3202 c-35 -24 -20 -86 23 -96 52 -14 85 58 41 88 -24 17 -47
20 -64 8z"/>
<path d="M8413 2862 c-15 -25 1 -52 31 -52 26 0 40 23 31 51 -8 24 -47 25 -62
1z"/>
<path d="M9377 2772 c-22 -24 -21 -35 2 -56 27 -25 64 -17 75 17 11 31 4 42
-34 52 -18 4 -31 1 -43 -13z"/>
<path d="M2767 2343 c-11 -10 -8 -51 3 -58 15 -10 47 4 55 23 9 25 -39 55 -58
35z"/>
<path d="M807 2302 c-22 -24 -21 -45 1 -65 24 -22 45 -21 65 1 22 24 21 45 -1
65 -24 22 -45 21 -65 -1z"/>
<path d="M9893 2300 c-42 -17 -20 -90 27 -90 37 0 50 12 50 44 0 51 -26 66
-77 46z"/>
<path d="M7402 2284 c-31 -21 -32 -99 -2 -109 11 -3 20 -11 20 -16 0 -13 53
-11 73 4 10 6 19 22 22 35 2 12 6 26 9 31 3 5 -6 23 -21 40 -31 35 -66 40
-101 15z"/>
<path d="M10740 2201 c-5 -11 -10 -24 -10 -30 0 -16 30 -41 51 -41 16 0 19 7
19 45 0 41 -2 45 -25 45 -14 0 -29 -8 -35 -19z"/>
<path d="M3706 1934 c-24 -23 -16 -48 17 -52 23 -3 27 0 27 21 0 36 -23 52
-44 31z"/>
<path d="M5562 1287 c-12 -13 -22 -31 -22 -40 0 -34 83 -53 102 -23 13 20 8
52 -10 69 -25 23 -45 21 -70 -6z"/>
<path d="M8232 1080 c-29 -47 -7 -100 42 -100 58 0 89 66 48 103 -26 24 -74
22 -90 -3z"/>
<path d="M5200 901 c-13 -25 13 -53 44 -49 27 4 33 38 10 55 -25 18 -42 16
-54 -6z"/>
<path d="M2100 880 c-27 -27 -25 -56 4 -75 25 -17 53 -11 73 14 18 23 16 38
-9 61 -27 25 -43 25 -68 0z"/>
<path d="M9443 893 c-26 -5 -63 -52 -63 -80 1 -45 68 -89 116 -77 31 8 57 59
50 98 -6 29 -49 68 -73 65 -4 -1 -18 -4 -30 -6z"/>
<path d="M944 855 c-38 -59 35 -106 89 -58 22 20 22 35 -2 61 -25 30 -66 28
-87 -3z"/>
<path d="M3983 811 c-33 -27 -31 -86 3 -117 33 -32 68 -30 104 5 36 34 38 66
5 104 -29 33 -78 36 -112 8z"/>
<path d="M10470 544 c-16 -41 -12 -59 18 -76 25 -15 29 -15 50 2 28 23 30 75
3 90 -34 18 -61 12 -71 -16z"/>
<path d="M7120 488 c-23 -25 -25 -40 -9 -69 30 -58 109 -36 109 30 0 20 -5 42
-12 49 -19 19 -65 14 -88 -10z"/>
<path d="M7858 59 c-10 -10 -18 -28 -18 -39 0 -18 7 -20 55 -20 30 0 55 3 55
8 -1 30 -16 52 -42 61 -17 6 -31 11 -32 10 -1 0 -9 -9 -18 -20z"/>
</g>
</svg>



    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
